/* divider */
@import (reference) "theme/variables.less";


.hr-divider {
    position: relative;
    z-index: 1;
    width: 100%;
    text-align: center;
    margin: 24px 0;

    &:before {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        margin-top: -1px;
        border-top-style: solid;
        border-top-width: 1px;
        border-top-color: @border-color-light;
        content: '';
    };
}

.hr-divider-text {
    position: relative;
    z-index: 2;
    display: inline-block;
    margin-bottom: -10px;
    padding: 0 10px;
    background: @site-bg-color;
    font-weight: bold;
    color: @text-color-dark;
}
