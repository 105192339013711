/* Login page */
/* divider */
.hr-divider {
  position: relative;
  z-index: 1;
  width: 100%;
  text-align: center;
  margin: 24px 0;
}
.hr-divider:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  margin-top: -1px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #DEDEDE;
  content: '';
}
.hr-divider-text {
  position: relative;
  z-index: 2;
  display: inline-block;
  margin-bottom: -10px;
  padding: 0 10px;
  background: #fff;
  font-weight: bold;
  color: #1f2836;
}
body {
  background-color: #F7F7F7;
}
.SectionLoginForm {
  display: flex;
  justify-content: center;
}
.SectionLoginForm-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 490px;
  margin-top: 80px;
  padding: 36px;
}
.SectionLoginForm-header {
  width: 100%;
  padding-top: 0;
  text-align: center;
}
.SectionLoginForm-header-img {
  display: block;
  margin: 0 auto 20px;
}
.SectionLoginForm-header-title {
  font-size: 19px;
  font-size: 1.1875rem;
  line-height: 1.47;
  font-weight: bold;
}
.SectionLoginForm-body {
  width: 100%;
  padding: 24px 0;
}
.SectionLoginForm-body-cta {
  width: 100%;
}
.SectionLoginForm-form {
  /* Increasing specificity to override fl-form */
  /* Increasing specificity to override fl-form */
  /* Increasing specificity to override fl-form */
}
.SectionLoginForm-form .SectionLoginForm-form-fieldset {
  margin-bottom: 0;
}
.SectionLoginForm-form-toggle {
  text-align: center;
}
.SectionLoginForm-form-cta {
  width: 100%;
  margin-bottom: 20px;
}
.SectionLoginForm-form .SectionLoginForm-form-remember {
  display: flex;
  align-items: center;
  padding-bottom: 0;
}
.SectionLoginForm-form .SectionLoginForm-form-label {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.43;
  margin-bottom: 0;
  font-weight: normal;
}
.SectionLoginForm-form-link {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.43;
  margin-left: auto;
}
.SectionLoginForm-footer {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.43;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 24px 0 0;
}
.SectionLoginForm-footer-text {
  margin-right: 10px;
}
