/**
 * Mixins
 *
 * We use mixins to replicate some of the used styling for specific layouts or
 * for browser vendor prefixes.
 *
 * Weight: -8
 *
 * Styleguide: Mixins
 */

/**
 * Miscellaneous
 *
 * Styleguide: Mixins - Misc
 */

/* Typography Variables for .type mixin */
@import "../theme/typography.less";

/**
 * Animations
 *
 * Styleguide: Mixins - Animations

 * .animated
 *
 * An override for the `.animated` class by Dan Eden (`animate.css`)
 * to define a duration for it.
 *
 * @duration - The duration of the animation
 *
 * Styleguide: Mixins - Animations - .animated
 */
.animated(@duration:1s){ // overload the animated class by Dan Eden so we can define a duration
    -webkit-animation-duration: @duration;
            animation-duration: @duration;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
}

// .no-animation
//
// Nullifies all animation.
//
// Styleguide Mixins - Animations - .no-animation
.no-animation{
    -webkit-animation: none;
    animation: none;
}

/**
 * .animation
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `animation` property.
 *
 * @name                 - The name of the animation.
 * @duration             - The duration of the animation.
 * @delay                - The delay of the animation to run.
 * @ease                 - The timing function of the animation.
 * @iteration-count      - How many times the animation will run.
 * @animation-fill-mode  - The `animation-fill-mode` value.
 * @animation-direction  - The `animation-direction` value.
 *
 * Styleguide: Mixins - Animations - .animation
 */
.animation (
    @name,
    @duration: 300ms,
    @delay: 0s,
    @ease: ease,
    @iteration-count: 1,
    @animation-fill-mode: none,
    @animation-direction: normal) {
    -webkit-animation: @name @duration @delay @ease @iteration-count @animation-fill-mode @animation-direction;
    animation: @name @duration @delay @ease @iteration-count @animation-fill-mode @animation-direction;
}

/**
 * .animation-delay
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `animation-delay` property.
 *
 * Styleguide: Mixins - Animations - .animation-delay
 */
.animation-delay (@delay: 1s) {
    -webkit-animation-delay: @delay;
    animation-delay: @delay;
}

/**
 * .keyframes
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `@keyframes` rule.
 *
 * Styleguide: Mixins - Animations - .keyframes
 */
.keyframes(@name, @content) {
    @-webkit-keyframes @name {
        @content();
    }

    @keyframes @name {
        @content();
    }
}

.box-sizing (@type: border-box) {
    -webkit-box-sizing: @type;
    box-sizing:         @type;
}

/**
 * Transitions
 *
 * Styleguide: Mixins - Transitions
 */

/**
 * .transition
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `transition` property.
 *
 * Styleguide: Mixins - Transitions - .transition
 */
.transition (@time: 1s, @ease: linear, @prop: all) {
    -webkit-transition: @prop @time @ease;
    transition: @prop @time @ease;
}

/**
 * .no-transition
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `transition` property to be set to `none`.
 *
 * Styleguide: Mixins - Transitions - .no-transition
 */
.no-transition {
    -webkit-transition: none;
    transition: none;
}

/**
 * .transition-delay
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `transition-delay` property.
 *
 * @delay - The amount of delay to be set.
 *
 * Styleguide: Mixins - Transitions - .transition-delay
 */
.transition-delay (@delay: 1s) {
    -webkit-transition-delay: @delay;
    transition-delay: @delay;
}

/**
 * Transforms
 *
 * These mixins are essentially vendor prefixes for `transform` property and
 * its values.
 *
 * Styleguide: Mixins - Transforms
 */

/**
 * .perspective
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `perspective` property.
 *
 * @arguments - Any values that are valid for `perspective`
 *
 * Styleguide: Mixins - Transforms - .perspective
 */
.perspective(...) {
    -webkit-perspective: @arguments;
    perspective: @arguments;
}

/**
 * .transform
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `transform` property.
 *
 * @arguments - Any values that are valid in `transform`
 *
 * Styleguide: Mixins - Transforms - .transform
 */
.transform(...) {
    -webkit-transform: @arguments;
    transform: @arguments;
}

/**
 * .translate
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `transform: translate()` property.
 *
 * @xpos - The x position.
 * @ypos - The y position.
 *
 * Styleguide: Mixins - Transforms - .translate
 */
.translate(@xpos:0, @ypos:0){
    .transform(translate(@xpos, @ypos));
}

/**
 * .rotation
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `transform: rotate()` property.
 *
 * @deg - The amount of degree.
 *
 * Styleguide: Mixins - Transforms - .rotation
 */
.rotation(@deg:5deg) {
  .transform(rotate(@deg));
}

/**
 * .scale
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `transform: scale()` property.
 *
 * @ratio - The ratio of the scale.
 *
 * Styleguide: Mixins - Transforms - .scale
 */
.scale(@ratio:1.5) {
  .transform(scale(@ratio));
}

/**
 * .transform-origin
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `transform-origin` property.
 *
 * @origin - The origin of the transform.
 *
 * Styleguide: Mixins - Transforms - .transform-origin
 */
.transform-origin (@origin: 50% 50%) {
    -webkit-transform-origin:@origin;
    transform-origin:@origin;
}

/**
 * .transition-timing-function
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `transition-timing-function` property.
 *
 * @timing - The timing function.
 *
 * Styleguide: Mixins - Transforms - .transition-timing-function
 */
.transition-timing-function(@timing: ease-out){
    transition-timing-function: @timing;
    -webkit-transition-timing-function: @timing;
}

/**
 * .transform-perspective
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `transform: perspective()` property.
 *
 * Deprecated: As the mixin affects the rotateY() value as well, we need to
 * refactor this properly first.
 *
 * @perspective - The perspective() value.
 * @rotateY     - The rotateY() value.
 *
 * Styleguide: Mixins - Transforms - .transform-perspective
 */
.transform-perspective(@perspective:60em, @rotateY:18deg){
    -webkit-transform: perspective(@perspective) rotateY(@rotateY);
    transform: perspective(@perspective) rotateY(@rotateY);
}

/**
 * .transform-style
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `transform-style` property.
 *
 * @value - The value.
 *
 * Styleguide: Mixins - Transforms - .transform-style
 */
.transform-style (@value) {
    -webkit-transform-style: @value;
    transform-style: @value;
}

.text-shadow (@string: 0 1px 0 rgba(255, 255, 255, .3)) {
    text-shadow: @string;
}

.hyphens {
    // Firefox alternative to word-break: break-word;
    -webkit-hyphens: auto;
       -moz-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
}

/**
 * .text-wrap
 *
 * Adds `word-break` and `word-wrap` as well as hyphens for cross-compatibility
 * in word breaking. Perfect for user inputs.
 *
 * Styleguide: Mixins - Misc. - .text-wrap
 */
.text-wrap() {
    word-break: break-word; /* old webkit */
    word-wrap: break-word; /* webkit */
    .hyphens(); /* firefox */
}

/**
 * Flexbox
 *
 * These mixins are responsible for the vendor prefixes for flexbox support.
 *
 * Styleguide: Mixins - Flexbox
 */

/**
 * .flex
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `display: flex` and `display: flex-inline`
 * declarations.
 *
 * @flexDisplay - `flex` or `inline-flex`.
 *
 * Styleguide: Mixins - Flexbox - .flex
 */
.flex(@flexDisplay: flex) {
    .ms-flex(@flexDisplay);

    & when (@flexDisplay = flex) {
        display: -webkit-box;
    }
    & when (@flexDisplay = inline-flex) {
        display: -webkit-inline-box;
    }

    display:~'-webkit-@{flexDisplay}';
    display: @flexDisplay;
}

/**
 * .flex-justify
 *
 * ** VENDOR PREFIX**
 *
 * Vendor prefix for the `justify-content` property.
 *
 * @justifyStyle - The value for `justify-content`.
 *
 * Styleguide: Mixins - Flexbox - .flex-justify
 */
.flex-justify(@justifyStyle) {
    & when (@justifyStyle = flex-start) {
        -webkit-box-pack: start;
    }

    & when (@justifyStyle = flex-end) {
        -webkit-box-pack: end;
    }

    & when (@justifyStyle = space-between) {
        -webkit-box-pack: justify;
    }

    & when (@justifyStyle = space-around) {
        -webkit-flex-pack: distribute;
    }

    & when (@justifyStyle = center) {
        -webkit-flex-pack: @justifyStyle;
    }

    .ms-flex-justify(@justifyStyle);
    -webkit-justify-content: @justifyStyle;
    justify-content: @justifyStyle;
}

/**
 * .flex-direction
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `flex-direction` property.
 *
 * @direction - The value for `flex-direction`.
 *
 * Styleguide: Mixins - Flexbox - .flex-direction
 */
.flex-direction(@direction) {
    & when (@direction = row) {
        -webkit-box-direction: normal;
        -webkit-box-orient: horizontal;
    }

    & when (@direction = row-reverse) {
        -webkit-box-direction: reverse;
        -webkit-box-orient: horizontal;
    }

    & when (@direction = column) {
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
    }

    & when (@direction = column-reverse) {
        -webkit-box-direction: reverse;
        -webkit-box-orient: vertical;
    }

    -webkit-flex-direction: @direction;
    -moz-flex-direction: @direction;
    -ms-flex-direction: @direction;
    flex-direction: @direction;
}

/**
 * .flex-align-items
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `align-items` property.
 *
 * @alignStyle - The value for `align-items`.
 *
 * Styleguide: Mixins - Flexbox - .flex-align-items
 */
.flex-align-items(@alignStyle) {
    & when (@alignStyle = flex-start) {
        -webkit-box-align: start;
    }

    & when (@alignStyle = flex-end) {
        -webkit-box-align: end;
    }

    & when (@alignStyle = baseline) {
        -webkit-box-align: @alignStyle;
    }

    & when (@alignStyle = center) {
        -webkit-box-align: @alignStyle;
    }

    & when (@alignStyle = stretch) {
        -webkit-box-align: @alignStyle;
    }

    -ms-flex-align: @alignStyle;
    -webkit-align-items: @alignStyle;
    align-items: @alignStyle;
}

/**
 * .flex-align-content
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `align-content` property.
 *
 * @alignStyle - The value for `align-content`.
 *
 * Styleguide: Mixins - Flexbox - .flex-align-content
 */
.flex-align-content(@alignStyle) {
    -webkit-align-content: @alignStyle;
    -moz-align-content: @alignStyle;

    & when (@alignStyle = flex-start) {
        -ms-flex-line-pack: start;
    }

    & when (@alignStyle = flex-end) {
        -ms-flex-line-pack: end;
    }

    -webkit-align-content: $value;
    -moz-align-content: $value;
    align-content: @alignStyle;
}

/**
 * .flex-align-self
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `align-self` property.
 *
 * @selfAlignStyle - The value for `align-self`.
 *
 * Styleguide: Mixins - Flexbox - .flex-align-self
 */
.flex-align-self(@selfAlignStyle) {
    .ms-flex-align-self(@selfAlignStyle);
    -webkit-align-self:@selfAlignStyle;
    align-self: @selfAlignStyle;
}

/**
 * .flex-order
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `order` property.
 *
 * @order - The order value.
 *
 * Styleguide: Mixins - Flexbox - .flex-order
 */
.flex-order(@order) {
    -ms-flex-order:@order;
    -webkit-order:@order;
    order:@order;
}

/**
 * .flex-wrap
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `flex-wrap` property.
 *
 * @wrap - `wrap` or `nowrap`.
 *
 * Styleguide: Mixins - Flexbox - .flex-wrap
 */
.flex-wrap(@wrap) {
    -ms-flex-wrap:@wrap;
    -webkit-flex-wrap:@wrap;
    flex-wrap:@wrap;
}

/**
 * .flex-grow
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `flex-grow` property.
 *
 * @proportion - The proportion factor of the element to grow.
 *
 * Styleguide: Mixins - Flexbox - .flex-grow
 */
.flex-grow(@proportion) {
    -ms-flex-positive: @proportion;
    -ms-flex-grow: @proportion;
    -webkit-flex-grow: @proportion;
    flex-grow: @proportion;
}

/**
 * .flex-shrink
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `flex-shrink` property.
 *
 * @proportion - The proportion factor of the element to shrink.
 *
 * Styleguide: Mixins - Flexbox - .flex-shrink
 */
.flex-shrink(@factor) {
    -ms-flex-negative: @factor;
    -ms-flex-shrink: @factor;
    -webkit-flex-shrink: @factor;
    flex-shrink: @factor;
}

/**
 * .flex-basis
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `flex-basis` property.
 *
 * @value - The basis value.
 *
 * Styleguide: Mixins - Flexbox - .flex-basis
 */
.flex-basis(@value) {
    /* Add a unit if flex-basis is not auto since IE needs a unit for flex-basis
       and we want to safeguard 0 to be 0px.
       We need 1px so that the minifier doesn't go crazy and minifies 0px to 0
       TODO: Once CleanCSS is updated to not remove 0px to 0 remove the
             unnecessary complexity.
     */

    & when (@value = 0), (@value = 0px) {
        -webkit-flex-basis: 1px;
        -ms-flex-preferred-size: 1px;
        flex-basis: 1px;
    }

    & when not (@value = 0) and not (@value = 0px) {
        -webkit-flex-basis: @value;
        -ms-flex-preferred-size: @value;
        flex-basis: @value;
    }
}

/* These are the conditional mixins for the different syntaxes Flexbox */
.ms-flex(@msFlex) when (@msFlex = flex) {
    display: -ms-flexbox;
}

.ms-flex(@msFlex) when (@msFlex = inline-flex) {
    display: -ms-inline-flexbox;
}

.ms-flex-justify(@msJustify) when (@msJustify = space-between) {
    -ms-flex-pack: justify;
}

.ms-flex-justify(@msJustify) when (@msJustify = space-around) {
    -ms-flex-pack: distribute;
}

.ms-flex-justify(@msJustify) when (@msJustify = flex-end) {
    -ms-flex-pack: end;
}

.ms-flex-justify(@msJustify) when (@msJustify = flex-start) {
    -ms-flex-pack: start;
}

.ms-flex-justify(@msJustify) when (@msJustify = center) {
    -ms-flex-pack: center;
}

.ms-flex-align-self(@msSelfAlign) when (@msSelfAlign = flex-end) {
    -ms-flex-item-align: end;
}

.ms-flex-align-self(@msSelfAlign) when (@msSelfAlign = flex-start) {
    -ms-flex-item-align: start;
}

.ms-flex-align-self(@msSelfAlign) when (@msSelfAlign = auto), (@msSelfAlign = center),(@msSelfAlign = baseline), (@msSelfAlign = stretch) {
    -ms-flex-item-align: @msSelfAlign;
}

/**
 * .flex-shorthand
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `flex` property.
 *
 * @flexGrow     - the `flex-grow` value
 * @flexShrink   - the `flex-shrink` value
 * @flexBasis    - the `flex-basis` value
 *
 * Styleguide: Mixins - Flexbox - .flex-shorthand
 */
.flex-shorthand(@flexGrow:0; @flexShrink:0; @flexBasis:0) {

    /* Add a unit if flex-basis is not auto since IE needs a unit for flex-basis
       and we want to safeguard 0 to be 1px.
       We need 1px so that the minifier doesn't go crazy and minifies 0px to 0
    */
    & when (@flexBasis = 0), (@flexBasis = 0px) {
        -ms-flex: @flexGrow @flexShrink 1px;
        -webkit-flex: @flexGrow @flexShrink 1px;
        flex: @flexGrow @flexShrink 1px;
    }

    & when not (@flexBasis = 0) and not (@flexBasis = 0px) {
        -ms-flex: @flexGrow @flexShrink @flexBasis;
        -webkit-flex: @flexGrow @flexShrink @flexBasis;
        flex: @flexGrow @flexShrink @flexBasis;
    }
}

/**
 * .appearance
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `appearance` property.
 *
 * @type - The appearance value.
 *
 * Styleguide: Mixins - Misc - .appearance
 */
.appearance (@type) {
    -webkit-appearance: @type;
    -moz-appearance: @type;
    appearance: @type;
}

/**
 * .box-shadow
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `box-shadow` property.
 *
 * Deprecated: Browser support is good enough. We don't need this anymore.
 * ([FED-312](https: *jira.flnltd.com/browse/FED-312))
 *
 * @shadow - The box-shadow value.
 *
 * Styleguide: Mixins - Misc - .box-shadow
 */
.box-shadow (@shadow) {
    box-shadow: @shadow;
}

/**
 * .inner-shadow
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `box-shadow` property with the `inset` value.
 *
 * Deprecated: Browser support is good enough. We don't need this anymore.
 * ([FED-312](https: *jira.flnltd.com/browse/FED-312))
 *
 * @horizontal - The horizontal offset value.
 * @vertical   - The vertical offset value.
 * @blur       - The blur value.
 * @alpha      - The alpha value of the black used for the shadow color.
 *
 * Styleguide: Mixins - Misc - .inner-shadow
 */
.inner-shadow(@horizontal:0, @vertical:1px, @blur:2px, @alpha: 0.4) {
    .box-shadow(inset @horizontal @vertical @blur rgba(0, 0, 0, @alpha));
}

/**
 * .list-unstyled
 *
 * Remove the default styling for `<ul>` and `<ol>` elements (bullet points).
 *
 * @margin   - The margin for the unstyled list. Defaults to `0`.
 * @padding  - The padding for the unstyled list. Defaults to `0`.
 *
 * Styleguide: Mixins - Misc - .list-unstyled
 */
.list-unstyled(@margin: 0, @padding: 0){
    margin: @margin;
    padding: @padding;
    list-style: none;
}

/**
 * Gradients
 *
 * Mixins range from the different gradient types to the gradients normally
 * used in different sections.
 *
 * Styleguide: Mixins - Gradients
 */

/**
 * .gradient
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for a linear gradient.
 *
 * Deprecated: This mixin is similar to .linear-gradient. Until we can refactor
 * or phase this out, do not use this mixin.
 *
 * @color     - The fallback color for older browsers that don't support
 *              gradients at all.
 * @start     - The starting color.
 * @stop      - The ending color.
 *
 * Styleguide: Mixins - Gradients - .gradient
 */
.gradient(@color: #F5F5F5, @start: @septenary-color-xxlight, @stop: @septenary-color-xxxlight) {
    background: @color;
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0%, @start), color-stop(100%, @stop));
    background: linear-gradient(to top, @start 0%, @stop 100%);
}

/**
 * .linear-gradient
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for a linear gradient.
 *
 * @direction        - The direction of the linear gradient.
 * @startColor       - The starting color.
 * @endColor         - The ending color.
 *
 * Styleguide: Mixins - Gradients - .linear-gradient
 */
.linear-gradient(@direction, @startColor, @endColor) {
    background-image: -webkit-linear-gradient(@direction, @startColor, @endColor);
    background-image: -moz-linear-gradient(@direction, @startColor, @endColor);
    background-image: -o-linear-gradient(@direction, @startColor, @endColor);
    background-image: linear-gradient(@direction, @startColor, @endColor);
}

/**
 * .linear-gradient
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for a linear gradient.
 *
 * @direction        - The direction of the linear gradient.
 * @startColor       - The starting color.
 * @endColor         - The ending color.
 *
 * Styleguide: Mixins - Gradients - .linear-gradient
 */
.radial-gradient(@startColor: @primary-color-light, @endColor: @primary-color){
    background: @startColor;
    background: -webkit-gradient(radial, center center, 0, center center, 460, from(@startColor), to(@endColor));
    background: -webkit-radial-gradient(circle, @startColor, @endColor);
    background: radial-gradient(circle, @startColor, @endColor)
}

/**
 * .hero-section-bg
 *
 * Generates the hero section radial gradient background.
 *
 * Deprecated: this is an old mixin for old pages.
 *
 * Styleguide: Mixins - Gradients - .hero-section-bg
 */
.hero-section-bg {
    background: #6597b0; /* Old browsers */
    background: -webkit-radial-gradient(center, ellipse cover,  #a0c5d5 0%,#6597b0 100%); /* Chrome10+,Safari5.1+ */
    background: radial-gradient(ellipse at center,  #a0c5d5 0%,#6597b0 100%); /* W3C */
}

/**
 * .HeroBackground
 *
 * Generates the hero background bootstrapping declarations to position a
 * background image to the container.
 *
 * This mixin should be applied to the parent sectioning container with the
 * `background-image` declaration being set in the layout file.
 *
 * @height    - The height of the hero section
 * @bg-color  - Fallback color for the hero section
 *
 * Styleguide: Mixins - Misc - .HeroBackground
 */
.HeroBackground (@height:420px; @bg-color:@site-bg-color-dark) {
    height: @height;
    background-color: @bg-color;
    background-repeat: no-repeat;
    background-position: bottom center;
    @media all and (min-width: 1920px) {
        background-position: bottom center fixed;
        background-size: cover;
    }
}

/**
 * CSS Triangle
 *
 * These mixins relate to creating triangles with CSS.
 *
 * Styleguide: Mixins - Misc - Triangle
 */

/**
 * .createTriangle
 *
 * A constructor mixin to create a triangle. Use it in pseudo-elements or
 * empty elements.
 *
 * @triangleOrientation - The orientation of the triangle.
 * @triangleColor       - The color of the triangle.
 * @arrowSize           - The size of the triangle.
 *
 * Styleguide: Mixins - Misc - Triangle - .createTriangle
 */
.createTriangle(@triangleOrientation: right, @triangleColor:@septenary-color-xxxlight, @arrowSize:10px) {
    width: 0;
    height: 0;

    & when (@triangleOrientation = up) {
        border-left: @arrowSize solid transparent;
        border-right: @arrowSize solid transparent;
        border-bottom: @arrowSize solid @triangleColor;
    }
    & when (@triangleOrientation = down) {
        border-left: @arrowSize solid transparent;
        border-right: @arrowSize solid transparent;
        border-top: @arrowSize solid @triangleColor;
    }
    & when (@triangleOrientation = right) {
        border-top: @arrowSize solid transparent;
        border-bottom: @arrowSize solid transparent;
        border-left: @arrowSize solid @triangleColor;
    }
    & when (@triangleOrientation = left) {
        border-top: @arrowSize solid transparent;
        border-bottom: @arrowSize solid transparent;
        border-right: @arrowSize solid @triangleColor;
    }
}

/**
 * .createArrow
 *
 * A constructor mixin to create an arrow.
 *
 * @arrowSize             - The size of the arrow.
 * @arrowOrientation      - The orientation of the arrow.
 * @arrowColor            - The color of the arrow.
 * @arrowWeight           - The weight of the arrow.
 *
 * Styleguide: Mixins - Misc - Triangle - .createArrow
 */
.createArrow(@arrowSize: 13px, @arrowOrientation: right, @arrowColor: @septenary-color-xxxlight, @arrowWeight: 2px) {
    width: @arrowSize;
    height: @arrowSize;
    .transform(rotate(45deg));

    & when (@arrowOrientation = right) {
        border-right: @arrowWeight solid @arrowColor;
        border-top: @arrowWeight solid @arrowColor;
    }

    & when (@arrowOrientation = left) {
        border-left: @arrowWeight solid @arrowColor;
        border-bottom: @arrowWeight solid @arrowColor;
    }
}

/**
 * .triangle
 *
 * Generates triangle bootstrapping declarations. This mixin must be used in
 * pseudo-elements and an explicit `border-color` and `border-width` must be set
 * along with the mixin usage.
 *
 *
 * Styleguide: Mixins - Misc - Triangle - .triangle
 */
.triangle{
    position: absolute;
    width: 0px;
    height: 0px;
    border-style: solid;
    content:'';
    -moz-transform: scale(.9999);
}

/**
 * .no-triangle
 *
 * Cancels out the .triangle mixin by setting the element's `::before`
 * and `::after` `border-style` and `border-width` to 0.
 *
 * Styleguide: Mixins - Misc - Triangle - .no-triangle
 */
.no-triangle{
    &:before,
    &:after{
        border-style: none;
        border-width: 0;
    };
}


/**
 * .roundedMask
 *
 * Creates a rounded mask on the element.
 *
 * @diameter - The diameter.
 *
 * Styleguide: Mixins - Misc - .roundedMask
 */
.roundedMask(@diameter:10px) {
    background: lighten(@septenary-color-xxlight, 3%);
    content: ' ';
    width: @diameter;
    height: @diameter;
    position: absolute;
    z-index: 2;
    .rounded(@diameter);
}

/**
 * .circle
 *
 * Creates a circle.
 *
 * @diameter - The diameter.
 *
 * Styleguide: Mixins - Misc - .circle
 */
.circle(@diameter:10px){
    width: @diameter;
    height: @diameter;
    border-radius: 50%;
}

/**
 * .vertical-align
 *
 * Vertically align the element with `transform` and absolute positioning.
 *
 * @pos        - The `position` value. Defaults to `absolute`.
 * @translateY - The `transform: translateY()` value.
 *
 * Styleguide: Mixins - Misc - .vertical-align
 */
.vertical-align(@pos:absolute, @translateY:-50%) {
    position: @pos;
    top: 50%;
    -webkit-transform: translateY(@translateY);
    transform: translateY(@translateY);
}

/**
 * .horizontal-align
 *
 * Horizontally align the element with `transform` and absolute positioning.
 *
 * @pos        - The `position` value. Defaults to `absolute`.
 *
 * Styleguide: Mixins - Misc - .horizontal-align
 */
.horizontal-align(@pos:absolute) {
    position: @pos;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

/**
 * .horizontal-vertical-align
 *
 * Horizontally and vertically align the element with `transform` and absolute
 * positioning.
 *
 * @pos        - The `position` value. Defaults to `absolute`.
 *
 * Styleguide: Mixins - Misc - .horizontal-vertical-align
 */
.horizontal-vertical-align(@pos:absolute) {
    position: @pos;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

/**
 * .absolute-cover
 *
 * Sets the element to `position: absolute` with the `top`, `right`, `bottom`
 * and `left` values to 0 to cover the parent element entirely.
 *
 * Styleguide: Mixins - Misc - .absolute-cover
 */
.absolute-cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

/*************************************************/
/* Image Sets */
.spritemap{
    background-image:url('/static/css/images/spritemap-var02.png');
}

.spritemap-payment{
    background-image:url('/static/css/images/sprite-payment.png');
}

.gamification-rewards-spritemap{
    background-image:url('/static/css/images/sprite-game-icons.png');
}

.gamification-items-spritemap{
    background-image:url('/static/css/images/sprite-game-items.png');
}

.gamification-spritemap{
    background-image:url('/static/css/images/sprite-game.png');
}

.gamification-badges-small-spritemap{
    background-image:url('/static/css/images/sprite-game-badges-small-var02.png');
}

.zlecenia-przez-logo-stylised{
    display:inline-block;
    background-image:url('/static/css/images/spritemap-var02.png');
    width:56px;
    height:36px;
    background-position:0px -200px;
}

/**
 * Typography
 *
 * These mixins govern the important parts of the site typography.
 *
 * Styleguide: Mixins - Typography
 */

/**
 * Fonts
 *
 * Mixins to set the font choices.
 *
 * Styleguide: Mixins - Typography - Fonts
 */

/**
 * .primary-font
 *
 * Sets the primary font family of the site as well as setting the
 * font-smoothing value.
 *
 * Styleguide: Mixins - Typography - Fonts - .primary-font
 */
.primary-font{
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/**
 * .primary-font-bold
 *
 * Sets the primary font family of the site with a bold font-weight.
 *
 * Deprecated: This mixin is unnecessarily terse. The styles of the
 * `font-family` and font smoothing are normally inherited from `body`. Just use
 * font-weight directly.
 *
 * Styleguide: Mixins - Typography - Fonts - .primary-font-bold
 */
.primary-font-bold{
    .primary-font;
     font-weight:900;
}

/**
 * .primary-font-light
 *
 * Sets the primary font family of the site with a normal font-weight.
 *
 * Deprecated: This mixin is unnecessarily terse. The styles of the
 * `font-family` and font smoothing are normally inherited from `body`. Just use
 * font-weight directly.
 *
 * Styleguide: Mixins - Typography - Fonts - .primary-font-light
 */
.primary-font-light{
    .primary-font;
     font-weight:normal;
}

/**
 * .secondary-font
 *
 * Sets the secondary font family of the site.
 *
 * Deprecated: We have no secondary fonts at the moment.
 *
 * Styleguide: Mixins - Typography - Fonts - .secondary-font
 */
.secondary-font {
    font-family:"museo_sans", Arial, sans-serif;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.rem(@property; @sizeValue) {
    @pxValue: @sizeValue;
    @remValue: (@sizeValue / @body-size);
    @{property}: ~"@{pxValue}px";
    @{property}: ~"@{remValue}rem";
}

/* font size mixn */
.font-size(@px, @base: @body-size) {
    font-size: @px;
    font-size: (@px / @base) * 1rem;
}

.type(@name) {
    @font-size: "font-size-@{name}";
    @line-height: "line-height-@{name}";
    .font-size(@@font-size);
    line-height: @@line-height;
}

/**
 * Typography Styles
 *
 * Typography based on type hierarchy from design team.
 * Only applicable to h1 and h2.
 *
 * Styleguide: Mixins - Typography - Styles
 */

/**
 * .masterHeading
 *
 * The master heading. 48px in size and black weighted with a line-height of
 * 1.3.
 *
 * Styleguide: Mixins - Typography - Styles - .masterHeading
 */
.masterHeading{
    font-size: 48px;
    font-size: 3rem;
    line-height: 1.3;
    font-weight: bold;
}

/**
 * .masterByline
 *
 * The master byline. 24px in size with a line-height 1.6.
 *
 * Styleguide: Mixins - Typography - Styles - .masterByline
 */
.masterByline{
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.6;
}

/**
 * .subHeading
 *
 * The subheading. 24px in size and black weighted with a line-height of 1.3.
 *
 * Styleguide: Mixins - Typography - Styles - .subHeading
 */
.subHeading{
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.3;
}

/**
 * .subByline
 *
 * The subheading byline. 18px in size with a line-height of 1.6.
 *
 * Styleguide: Mixins - Typography - Styles - .subByline
 */
.subByline{
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.6;
}

/**
 * .heading1
 *
 * The heading level 1. 34px in size and black weighted with a line-height of
 * 1.3.
 *
 * Styleguide: Mixins - Typography - Styles - .heading1
 */
.heading1 {
    font-size: 34px;
    font-size: 2.125rem;
    line-height: 1.3;
    font-weight: bold;
}

/**
 * .heading2
 *
 * The heading level 2. 24px in size and bold weighted with a line-height of
 * 1.3.
 *
 * Styleguide: Mixins - Typography - Styles - .heading2
 */
.heading2 {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.3;
    font-weight: bold;
}

/**
 * .heading2-byline
 *
 * The heading level 2 byline. 18px in size with a line-height of 1.6.
 *
 * Styleguide: Mixins - Typography - Styles - .heading2-byline
 */
.heading2-byline {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.6;
    font-weight: normal;
}

/**
 * .heading3
 *
 * The heading level 3. 18px in size and bold weighted with a line-height of
 * 1.3.
 *
 * Styleguide: Mixins - Typography - Styles - .heading3
 */
.heading3 {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.3;
    font-weight: bold;
}

/**
 * .heading-form
 *
 * The heading for forms. 18px in size and bold weighted with a line-height of
 * 1.3.
 *
 * Styleguide: Mixins - Typography - Styles - .heading-form
 */
.heading-form {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.3;
    font-weight: bold;
}

/**
 * .body-text
 *
 * The body text. 15px in size with a line-height of 1.6.
 *
 * Styleguide: Mixins - Typography - Styles - .body-text
 */
.body-text {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 1.6;
    font-weight: normal;
}

/**
 * .detail-text
 *
 * The body text. 13px in size with a line-height of 1.4.
 *
 * Styleguide: Mixins - Typography - Styles - .detail-text
 */
.detail-text {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 1.4;
    font-weight: normal;
}

/**
 * .text-landing-h1
 *
 * Heading level 1 for landing pages
 *
 * Styleguide: Mixins - Typography - Styles - .text-landing-h1
 */
.text-landing-h1 {
    font-size: 56px;
    font-size: 3.5rem;
    line-height: 1.1;
    font-weight: bold;
}

/**
 * .text-landing-h2
 *
 * Heading level 2 for landing pages
 *
 * Styleguide: Mixins - Typography - Styles - .text-landing-h2
 */
.text-landing-h2 {
    margin-bottom: 20px;
    font-size: 42px;
    font-size: 2.625rem;
    line-height: 1.1;
    font-weight: bold;
}

/**
 * .text-landing-tagline
 *
 * Tagline for landing pages
 *
 * Styleguide: Mixins - Typography - Styles - .text-landing-tagline
 */
.text-landing-tagline {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 1.6;
    font-weight: normal;
}

/**
 * .text-landing-pullQuote
 *
 * Pull quote for landing pages
 *
 * Styleguide: Mixins - Typography - Styles - .text-landing-pullQuote
 */
.text-landing-pullQuote {
    font-size: 32px;
    font-size: 2rem;
    line-height: 1.4;
    font-weight: normal;
}

/**
 * .text-landing-body
 *
 * Body text for landing pages
 *
 * Styleguide: Mixins - Typography - Styles - .text-landing-body
 */
.text-landing-body {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.7;
    font-weight: normal;
}

/**
 * .dark-link-color
 *
 * Sets a link color and the hover color for the element.
 *
 * @baseColor   - The color of the link.
 * @hoverColor  - The hover color of the link.
 *
 * Styleguide: Mixins - Misc - .dark-link-color
 */
.dark-link-color(@baseColor: @text-color-dark, @hoverColor: @link-color) {
    color: @baseColor;
    &:hover {
        color: @hoverColor;
        text-decoration: none;
    }
}

/**
 * Sections
 *
 * Sections generator mixins.
 *
 * Styleguide: Mixins - Sections
 */

/**
 * .site-section-inner
 *
 * Creates an inner section based on the container widths that we set.
 * This is useful to limit the content to use the container width used
 * globally.
 *
 * @width             - The fixed width for the container in fixed pages.
 *                      Defaults to @site-width-fixed.
 * @padding           - The padding to add in the container. Defaults to
 *                      @site-section-padding.
 * @width--responsive - The width for the container in responsive pages.
 *                      Defaults to @site-maxwidth-responsive.
 *
 * Styleguide: Mixins - Sections - .site-section-inner
 */
.site-section-inner(@width: @site-width-fixed, @padding: @site-section-padding, @width--responsive: @site-maxwidth-responsive) {
    width: @width;
    min-width: @width;
    margin: 0 auto;
    padding-left: @padding;
    padding-right: @padding;

    html:not(.is-responsive) &{
        @media (min-width: (@width + (@padding*2))) {
            padding-left:0;
            padding-right:0;
        }
    }

    html.is-responsive &{
        @media (min-width: @width) {
            width: 100%;
            max-width: @width--responsive;
        }

        @media (min-width: (@width--responsive + (@padding*2))) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    html.is-mobile-first &{
        width:100%;
        min-width:0;
        max-width:@width--responsive;
    }

    html.is-mobile-first:not(.is-responsive) &{
        padding-left:@padding;
        padding-right:@padding;
    }
}

/**
 * .fadedBorderX
 *
 * Creates a faded horizontal line that spans from transparent to
 * `rgba(147, 147, 147, 1)` to transparent again.
 *
 * Styleguide: Mixins - Misc - .fadedBorderX
 */
.fadedBorderX {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right,  rgba(0,0,0,0) 0%,rgba(147,147,147,1) 50%,rgba(0,0,0,0) 100%);
}

/**
 * .calc
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the CSS `calc()` function value.
 *
 * @calcProperty - The property to use `calc()` on.
 *                 Write the value as ~"[value]".
 * @calcValue    - The arguments on the `calc()` function.
 *                 Write the value as ~"[value]".
 *
 * Styleguide: Mixins - Misc - .calc
 */
.calc(@calcProperty: ~"width", @calcValue: ~"100vh - 20px") {
    @{calcProperty}: -webkit-calc(@calcValue);
    @{calcProperty}: -moz-calc(@calcValue);
    @{calcProperty}: calc(@calcValue);
}

/**
 * Card
 *
 * Mixins for card styling.
 *
 * Styleguide: Mixins - Card
 */

/**
 * .card
 *
 * Create a card styling for the element.
 *
 * @drop-shadow - The drop shadow color value. Defaults to @card-shadow.
 *
 * Styleguide: Mixins - Card - .card
 */
.card() {
    .box-shadow-level-1();
    border-radius: @border-radius;
    background: @septenary-color-xxxlight;
}

.box-shadow-level-1() {
    box-shadow: 0 0 4px 0 @card-shadow-level-1, 0 2px 4px 0 @card-shadow-level-1-secondary;
}

.box-shadow-level-02() {
    box-shadow: 0 0 6px 0 @card-shadow-level-2, 0 4px 10px 0 @card-shadow-level-2-secondary;
}

.box-shadow-level-03() {
    box-shadow: 0 0 12px 0 @card-shadow-level-3, 0 8px 24px 0 @card-shadow-level-3-secondary;
}

/**
 * .background-clip
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `background-clip` property.
 *
 * @argument - The value for `background-clip`.
 *
 * Styleguide: Mixins - Misc - .background-clip
 */
.background-clip(@argument: padding-box) {
  -moz-background-clip: @argument;
  -webkit-background-clip: @argument;
  background-clip: @argument;
}

/**
 * .border-radius
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `border-radius` property.
 *
 * @topleft        - The top left radius.
 * @bottomright    - The bottom right radius.
 * @bottomleft     - The bottom left radius.
 * @topleft        - The top left radius.
 *
 * Styleguide: Mixins - Misc - .border-radius
 */
.border-radius(@topright: 0, @bottomright: 0, @bottomleft: 0, @topleft: 0) {
    border-radius: @topleft @topright @bottomright @bottomleft;
    .background-clip(padding-box);
}

/**
 * .rounded
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for defaulting the `border-radius` property to all sides.
 *
 * @radius - The radius for all sides.
 *
 * Styleguide: Mixins - Misc - .rounded
 */
.rounded(@radius: 2px) {
    .border-radius(@radius, @radius, @radius, @radius);
}

/**
 * .opacity
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `opacity` property.
 *
 * Deprecated: Browser support is good enough. We don't need this anymore.
 * ([FED-312](https://jira.flnltd.com/browse/FED-312))
 *
 * @opacity - The opacity value.
 *
 * Styleguide: Mixins - Misc - .opacity
 */
.opacity(@opacity: 0.5) {
    opacity: @opacity;
}

/**
 * .drop-shadow
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `box-shadow` property for drop shadow effect.
 *
 * Deprecated: Browser support is good enough. We don't need this anymore.
 * ([FED-312](https://jira.flnltd.com/browse/FED-312))
 *
 * @x-axis - The horizontal offset value.
 * @y-axis - The vertical offset value.
 * @blur   - The blur value.
 * @alpha  - The alpha value of the black used for the shadow color.
 *
 * Styleguide: Mixins - Misc - .drop-shadow
 */
.drop-shadow(@x-axis: 0, @y-axis: 1px, @blur: 2px, @alpha: 0.1) {
    box-shadow: @x-axis @y-axis @blur rgba(0, 0, 0, @alpha);
}

/**
 * .user-select
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the `user-select` property.
 *
 * @argument - The value for `user-select`.
 *
 * Styleguide: Mixins - Misc - .user-select
 */
.user-select(@argument: none) {
    -webkit-user-select: @argument;
    -moz-user-select: @argument;
    -ms-user-select: @argument;
    user-select: @argument;
}

/**
 * .columns
 *
 * **VENDOR PREFIX**
 *
 * Vendor prefix for the CSS columns syntax.
 *
 * @colwidth             - The column width
 * @colcount             - The column count
 * @colgap               - The column gap
 * @columnRuleColor      - The column rule color
 * @columnRuleStyle      - The column rule style
 * @columnRuleWidth      - The column rule width
 *
 * Styleguide: Mixins - Misc - .columns
 */
.columns(
    @colwidth: 250px,
    @colcount: 0,
    @colgap: 50px,
    @columnRuleColor: @septenary-color-xxlight,
    @columnRuleStyle: solid,
    @columnRuleWidth: 1px
) {
    -moz-column-width: @colwidth;
    -moz-column-count: @colcount;
    -moz-column-gap: @colgap;
    -moz-column-rule-color: @columnRuleColor;
    -moz-column-rule-style: @columnRuleStyle;
    -moz-column-rule-width: @columnRuleWidth;
    -webkit-column-width: @colwidth;
    -webkit-column-count: @colcount;
    -webkit-column-gap: @colgap;
    -webkit-column-rule-color: @columnRuleColor;
    -webkit-column-rule-style: @columnRuleStyle;
    -webkit-column-rule-width: @columnRuleWidth;
    column-width: @colwidth;
    column-count: @colcount;
    column-gap: @colgap;
    column-rule-color: @columnRuleColor;
    column-rule-style: @columnRuleStyle;
    column-rule-width: @columnRuleWidth;
}

.column-count(@column-width) {
    -webkit-column-count: @column-width;
    -moz-column-count: @column-width;
    column-count: @column-width;
}

.column-gap(@gap-width) {
    -webkit-column-gap: @gap-width;
    -moz-column-gap: @gap-width;
    column-gap: @gap-width;
}

/**
 * .visibly-hidden
 *
 * A mixin to visibly hide the element from the viewport but screen readers
 * can still pick them up and read them up. Useful for accessibility.
 *
 * Styleguide: Mixins - Misc - .visibly-hidden
 */
.visibly-hidden() {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}
