@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";
@import "modules/HrDivider.less";

body {
    background-color: @site-bg-color-mid;
}

.SectionLoginForm {
    @this: SectionLoginForm;

    display: flex;
    justify-content: center;

    &-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 490px;
        margin-top: 80px;
        padding: 36px;
    }

    &-header {
        width: 100%;
        padding-top: 0;
        text-align: center;

        &-img {
            display: block;
            margin: 0 auto 20px;
        }

        &-title {
            .type(heading3);
            font-weight: bold;
        }
    }

    &-body {
        width: 100%;
        padding: 24px 0;

        &-cta {
            width: 100%;
        }
    }

    &-form {
        /* Increasing specificity to override fl-form */
        .@{this}-form-fieldset {
            margin-bottom: 0;
        }

        &-toggle {
           text-align: center;
        }

        &-cta {
            width: 100%;
            margin-bottom: 20px;
        }

        /* Increasing specificity to override fl-form */
        .@{this}-form-remember {
            display: flex;
            align-items: center;
            padding-bottom: 0;
        }

        /* Increasing specificity to override fl-form */
        .@{this}-form-label {
            .type(ui);
            margin-bottom: 0;
            font-weight: normal;
        }

        &-link {
            .type(ui);
            margin-left: auto;
        }
    }

    &-footer {
        .type(ui);
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 24px 0 0;

        &-text {
            margin-right: 10px;
        }
    }
}